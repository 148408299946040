<template>
  <div>
    <v-container fluid>

      <v-row>
        <v-col cols="12">

          <v-alert v-if="offline" border="bottom" class="mb-3 fs-14"
                   color="warning" dark
                   dense icon="mdi-access-point-off"
                   prominent>
            Vous avez perdu votre connexion Internet. L'application passera automatiquement en mode hors ligne.
          </v-alert>

          <v-card class="shadow rounded-lg">

            <v-card-title class="pa-0">

              <v-tabs v-model="view"
                      background-color="transparent"
                      centered
                      height="65"
                      show-arrows>
                <v-tab tab-value="all">
                  <v-icon left>mdi-clipboard-text-outline</v-icon>
                  Tous
                </v-tab>
                <v-tab tab-value="waiting">
                  <v-icon left>mdi-clock-alert-outline</v-icon>
                  En attente
                </v-tab>
                <v-tab tab-value="in_progress">
                  <v-icon left>mdi-clock-outline</v-icon>
                  En cours
                </v-tab>
                <v-tab tab-value="completed">
                  <v-icon left>mdi-check-circle-outline</v-icon>
                  Terminé
                </v-tab>
              </v-tabs>

            </v-card-title>

            <v-divider/>


            <v-card-text>

              <v-row v-if="isLoading">
                <v-col v-for="(item,i) in 8" :key="i" cols="12" lg="4" md="4">
                  <v-card class="rounded-lg" outlined>
                    <v-card-text>
                      <v-skeleton-loader type="image,article"/>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>

              <v-row v-else>

                <v-col v-if="!filtredCampaigns.length" cols="12">
                  <v-card class="transparent" flat>
                    <v-card-text>
                      <NoResult/>
                    </v-card-text>
                  </v-card>
                </v-col>

                <v-col v-for="(item,index) in filtredCampaigns"
                       :key="index"
                       class="d-flex flex-column"
                       cols="12"
                       lg="4"
                       md="4">

                  <v-card class="rounded-lg flex d-flex flex-column" outlined>
                    <v-card-title>
                      <v-avatar rounded size="50">
                        <v-img v-if="item.image" :src="$baseUrl + item.image"
                               class="zoom-pointer rounded-lg"
                               max-width="50"
                               min-height="50"
                               @click="$refs.lightbox.open(item.image)"/>

                        <v-img v-else
                               :src="require('@/assets/no-image.png')"
                               class="rounded-lg"
                               max-width="50"
                               min-height="50"></v-img>
                      </v-avatar>
                      <v-spacer/>
                      <v-chip :class="'bg-'+item.remaining_days.color+'-subtle'"
                              :text-color="item.remaining_days.color"
                              class="font-weight-medium fs-13"
                              dark
                              label
                              small>
                        {{ item.remaining_days.value }}
                      </v-chip>
                    </v-card-title>

                    <v-card-text class="flex">

                      <h3 class="pb-3 font-weight-medium grey--text text--darken-3">
                        {{ item.name }}
                      </h3>

                      <div class="mb-3">

                        <div class="d-block text-no-wrap">
                          <v-icon color="primary" small>mdi-calendar</v-icon>
                          <span class="font-weight-medium"> Date de début : </span>
                          {{ $func.dateFormat(item.start_date) }}
                        </div>

                        <div class="d-block text-no-wrap mt-2">
                          <v-icon color="primary" small>mdi-calendar</v-icon>
                          <span class="font-weight-medium"> Date de fin : </span>
                          {{ $func.dateFormat(item.end_date) }}
                        </div>

                        <div class="d-block text-no-wrap mt-2">
                          <v-icon color="primary" small>mdi-tag</v-icon>
                          <span class="font-weight-medium"> Type : </span>
                          {{ $func.campaignType(item.type) }}
                        </div>

                      </div>

                      <p class="grey--text mb-0">
                        {{ item.description }}
                      </p>

                      <v-row no-gutters>
                        <v-col cols="6">

                          <v-list-item class="pa-0">
                            <v-list-item-avatar class="bg-primary-subtle rounded-lg" size="30" tile>
                              <v-icon color="primary" small>mdi-shopping</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>{{ item.total }}</v-list-item-title>
                              <v-list-item-subtitle>Total</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>

                        </v-col>
                        <v-col cols="6">
                          <v-list-item>
                            <v-list-item-avatar class="bg-orange-subtle rounded-lg" size="30" tile>
                              <v-icon color="orange" small>mdi-circle</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>{{ item.switch }}</v-list-item-title>
                              <v-list-item-subtitle>Switch</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                        <v-col cols="6">
                          <v-list-item class="pa-0">
                            <v-list-item-avatar class="bg-blue-subtle rounded-lg" size="30" tile>
                              <v-icon color="blue" small>mdi-circle</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>{{ item.sale }}</v-list-item-title>
                              <v-list-item-subtitle>Fidélisation</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                        <v-col cols="6">
                          <v-list-item>
                            <v-list-item-avatar class="bg-secondary-subtle rounded-lg" size="30" tile>
                              <v-icon color="secondary" small>mdi-circle</v-icon>
                            </v-list-item-avatar>
                            <v-list-item-content>
                              <v-list-item-title>{{ item.refusal }}</v-list-item-title>
                              <v-list-item-subtitle>Refus</v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                      </v-row>

                    </v-card-text>

                    <v-divider/>

                    <v-card-actions class="pa-4">
                      <v-btn :to="'/animator-view/campaigns/'+item.id+'/program'"
                             color="primary"
                             depressed>
                        <v-icon left>mdi-calendar-month-outline</v-icon>
                        Programme
                      </v-btn>
                      <v-btn :to="'/animator-view/campaigns/'+item.id+'/program?&salepoint='+item.currant_pointing.salepoint_id"
                             v-if="item.currant_pointing"
                             outlined
                             color="primary"
                             depressed>
                        <v-icon left>mdi-shopping</v-icon>
                        Continuer
                      </v-btn>
                    </v-card-actions>

                  </v-card>

                </v-col>

              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <Lightbox ref="lightbox"/>

    </v-container>
  </div>
</template>

<script>
import {HTTP} from "@/http-common";

export default {
    data() {
        return {
            offline: false,
            isLoading: false,
            view: 'all',
        }
    },

    methods: {
        getCampaigns() {
            this.isLoading = true
            HTTP.get('/animator-view/campaigns').then((res) => {
                this.isLoading = false
                this.offline = false
                this.$store.dispatch('campaigns', res.data.data)
            }).catch(err => {

                if (!err.response) {
                    this.offline = true
                }

                this.isLoading = false
                console.log(err)
            })
        },
    },
    created() {
        this.getCampaigns()
    },
    computed: {
        filtredCampaigns() {
            if (this.view !== 'all') {
                return this.$store.state.campaigns.filter(el => el.remaining_days.state === this.view)
            } else {
                return this.$store.state.campaigns
            }
        },
    },
}
</script>

<style scoped>

</style>